import React, { useEffect, useState } from 'react'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import './globalStyles.css'
import './swimlane.css'
import { withTheme } from 'theming'
import { isEmpty, shuffle } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import VenueCard from '../../contentCard/venueCard'
import { useTranslation } from 'react-i18next'

const DefaultVenueSwimlane = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  theme,
  onAdd,
  onSwap,
}) => {
  const { t } = useTranslation()
  //   const { data, title, filters, seeMoreVisible, type, theme } = props
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const { customNavigate, isBrowser, LG_SIZE, SM_SIZE, VENUE_LISTING } = useTemplateHook()
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [venueData, setVenueData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: venueList }, venueListCall] = useAxios(
    {
      url: VENUE_LISTING,
      method: 'get',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(4)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(3)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    venueListCall({
      params: {
        page_size: 15,
        page: 1,
        order_by: data?.order_by === 'title' ? `title.${language}` : data?.order_by,
        reverse: data?.reverse,
      },
    })
  }, [])

  useEffect(() => {
    if (venueList && venueList.success) {
      setVenueData(shuffle(venueList.data))
    }
  }, [venueList])

  const handleSeeMore = () => {
    customNavigate('DIRECT', `/venues`, isEdit)
    // let filters =
    //   data.type === 'STATIC'
    //     ? {
    //         content_id: data.content_data,
    //       }
    //     : {
    //         system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
    //         custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
    //       }
    // const encoded = btoa(JSON.stringify(filters))
    // customNavigate(
    //   'DIRECT',
    //   `/content/filter/${createSlug(data.name)}?filters=${encoded}&${
    //     data.type === 'STATIC' ? '' : 'filterV2=true'
    //   }`,
    //   isEdit,
    // )
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} ${
        isEdit
          ? 'pb-10 relative lg:pt-12 xl:pt-10 2xl:pt-14 mt-2'
          : 'mt-6 md:mt-4 lg:mt-20 xl:mt-16 2xl:mt-24 '
      } `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onEdit={() => handleModal()}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div style={{ backgroundColor: theme.c1 }} className="flex items-baseline">
        <p
          style={{ color: theme.c3 }}
          className="sm:text-[0.8rem] md:text-[1.5rem] font-bold ml-6 md:ml-16 xl:ml-28"
        >
          {data?.name}
        </p>
        {venueData.length > 14 && (
          <p
            style={{ cursor: 'pointer' }}
            className={
              'text-blue-400 text-xs md:text-md  ml-[4px] cursor-pointer hover:opacity z-30 mt-3'
            }
            onClick={handleSeeMore}
          >
            {t('see_more')}
          </p>
        )}
      </div>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={venueData.length}
        visibleSlides={visibleSlides}
        step={5}
      >
        <div
          className={
            isEmpty(venueData)
              ? 'animate-pulse swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[14rem]'
              : 'swimlane-container-dock h-[8.5rem] md:h-60 lg:h-[14rem]'
          }
          style={{ backgroundColor: theme.c1 }}
        >
          <Slider className="slide-for-venues">
            {venueData.map((item, index) => {
              return (
                <Slide key={index} index={index} className="pb-0">
                  <div className="pt-2 md:pt-4 lg:pt-8 xl:pt-10">
                    <VenueCard item={item} isEdit={isEdit} />
                  </div>
                </Slide>
              )
            })}
          </Slider>
          {isBrowser && (
            <>
              {venueData.length > visibleSlides ? (
                <ButtonBack>
                  <IoIosArrowBack className="left-Swimlane-arrow " />
                </ButtonBack>
              ) : null}
              {venueData.length > visibleSlides ? (
                <ButtonNext>
                  <IoIosArrowForward className="right-Swimlane-arrow " />
                </ButtonNext>
              ) : null}
            </>
          )}
        </div>
      </CarouselProvider>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(DefaultVenueSwimlane)
