import React, { useContext, useEffect, useState } from 'react'
import { RELATED_CONTENT } from '../../utils/apiUrls'
import useAxios from 'axios-hooks'
import InComponentLoader from '../../components/InComponentLoader'
import { isEmpty } from 'lodash'
import { getImage, getReleaseDate } from '../../utils/helper'
import CustomImage from '../../components/customImage/customImage'
import { AppContext } from '../../context/app'
import PlayIcon from '../../assets/play-circle-thick.svg'
import AudioIcon from '../../assets/audio-thumbnail-icon.svg'
import { useNavigate } from 'react-router'
import LiveIcon from '../../assets/live-icon-channel.png'
import LiveComments from './liveComments'
import { useTranslation } from 'react-i18next'

const PlayerPageSidebar = ({ detailObj, contentId, setNextContent }) => {
  const [activeTab, setActiveTab] = useState(0)
  const { accountObj } = useContext(AppContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [{ data: relatedVideos, loading: relatedVideosLoading }] = useAxios({
    url: `${RELATED_CONTENT}${contentId}`,
    method: 'post',
    data: { filters: {} },
  })

  const handleRedirect = (content) => {
    if (content.video_type === 'PLAYLIST' || content.audio_type === 'PLAYLIST') {
      navigate(`/playlist/${content.content_id}`)
      return
    }
    if (content.video_type === 'MPVP' || content.audio_type === 'MPAP') {
      navigate(`/parent-content/${content.content_id}`)
    } else {
      navigate(`/content-watch/${content?.content_id}`)
    }
  }

  useEffect(() => {
    if (relatedVideos?.success && !isEmpty(relatedVideos?.data)) {
      setNextContent(relatedVideos?.data[0])
    }
  }, [relatedVideos])

  const getEndTime = (content) => {
    return new Date(content.live_expiry_time + 'Z').getTime()
  }

  const checkIsLive = (contentObj) => {
    let publishTime = new Date(contentObj.publish_time + 'Z')
    if (
      (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
        new Date(
          publishTime.setSeconds(publishTime.getSeconds() + contentObj.duration_in_s),
        ).getTime() > Date.now() &&
        contentObj?.video_type === 'VOD_LIVE') ||
      (new Date(contentObj.publish_time + 'Z').getTime() < Date.now() &&
        new Date(contentObj.live_expiry_time + 'Z').getTime() > Date.now())
    ) {
      return true
    }
    return false
  }

  return (
    <div className="w-full h-[470px] lg:h-full ">
      <InComponentLoader loading={relatedVideosLoading} />
      <div style={{}} className=" flex ">
        <div
          style={{
            color: activeTab === 0 ? '#09e9e9' : '#acacac',

            borderRadius: '10px 10px 0  0 ',
            background: activeTab === 0 ? '#000' : '#2c3747',
          }}
          className={`${
            activeTab === 0 && 'font-semibold'
          } cursor-pointer text-center items-center py-3 text-xs md:text-base flex-1 `}
          onClick={() => setActiveTab(0)}
        >
          {t('related_contents')}
        </div>
        {detailObj?.live_comments_applicable && (
          <div
            style={{
              color: activeTab === 1 ? '#09e9e9' : '#acacac',
              borderRadius: '10px 10px 0  0 ',
              marginLeft: '0.5%',
              background: activeTab === 1 ? '#000' : '#2c3747',
            }}
            className={`${
              activeTab === 1 && 'font-semibold'
            } cursor-pointer text-center items-center py-3 text-xs md:text-base flex-1 `}
            onClick={() => setActiveTab(1)}
          >
            {t('live_comments')}
          </div>
        )}
      </div>
      <div style={{ borderRadius: '0 0 10px 10px' }} className=" mt-1 h-full overflow-hidden">
        {activeTab === 0 && (
          <div
            // style={{ aspectRatio: '593/1209' }}
            style={{ borderRadius: '0 0 10px 10px' }}
            className="w-full bg-black px-1 lg:px-2 h-[89%] lg:aspect-[593/1209] lg:h-[96.25%] scrollbar-class"
          >
            {relatedVideos &&
              !isEmpty(relatedVideos?.data) &&
              relatedVideos?.data.map((content, index) => {
                return (
                  <div
                    key={index}
                    style={{ borderBottom: '1px solid #ffffff80' }}
                    className={` flex w-full py-3 px-3`}
                  >
                    <div
                      style={{ aspectRatio: '1.77' }}
                      className="relative flex justify-center items-center cursor-pointer h-[85px] lg:h-[90px] 2xl:h-[100px]"
                      onClick={() => handleRedirect(content)}
                    >
                      <CustomImage
                        imageUrl={getImage(accountObj?.cf_domain_name, content?.thumbnail)}
                        imgClass="w-full h-full z-0 absolute rounded-lg"
                        loaderClass={'w-full h-full z-0 absolute rounded-lg'}
                        imgAlt={'play'}
                      />
                      {(content?.video_type?.includes('LIVE') || content?.audio_type === 'LIVE') &&
                      getEndTime(content) > Date.now() ? (
                        checkIsLive(content) ? (
                          <img
                            src={LiveIcon}
                            className="absolute h-4 m-2 z-10 top-0 right-0"
                            alt="LiveIcon"
                          />
                        ) : (
                          <div
                            className=" rounded text-white absolute text-center h-4 m-2 z-10 top-0 right-0"
                            style={{
                              background: 'rgb(99, 99, 99)',
                              padding: '0px 4px',
                              fontSize: '10px',
                            }}
                          >
                            {t('upcoming')}
                          </div>
                        )
                      ) : null}

                      {content?.asset_type === 'VIDEO' ? (
                        <img
                          style={{ width: '40px', height: '40px' }}
                          className="absolute z-10"
                          src={PlayIcon}
                          alt="PlayIcon"
                        />
                      ) : (
                        <img
                          style={{ width: '40px', height: '40px' }}
                          className="absolute z-10"
                          src={AudioIcon}
                          alt="AudioIcon"
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: '5%', color: '#fff' }}>
                      <p className="font-semibold 2xl:text-xl xl:text-lg lg:text-base line-clamp-2">
                        {content.title}
                      </p>
                      {!isEmpty(content.release_time) && (
                        <p className="text-sm mt-1">
                          {t('release_date')} : {getReleaseDate(content.release_time)}
                        </p>
                      )}
                      <p className="text-sm mt-1 line-clamp-1">{content.description}</p>
                    </div>
                  </div>
                )
              })}
          </div>
        )}

        {activeTab === 1 && (
          <div className="w-full h-[89%] lg:h-auto" style={{ aspectRatio: '593/762' }}>
            <LiveComments />
          </div>
        )}
      </div>
    </div>
  )
}

export default PlayerPageSidebar
