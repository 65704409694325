import React from 'react'
import atgLog from './atg.png'
import atgBg from './atg_bg.webp'

const AccountDisable = () => {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: 'url(' + atgBg + ')' }}
    >
      <div className="h-screen w-screen flex items-center">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center px-5 text-white ">
          <div className="max-w-prose">
            <div className="flex items-center justify-center px-5 text-white ">
              <img src={atgLog} alt="" width={'40%'} />
            </div>
            <div className="text-center text-2xl sm:text-3xl md:text-5xl font-dark font-bold ">
              COMING SOON
            </div>
            <p className="text-center text-base sm:text-xl md:text-2xl font-light leading-normal">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Sorry, we're down for scheduled maintenance.{' '}
            </p>
            <br /> <br /> <br />
            <div className="py-20">
              <p className="text-center text-sm sm:text-base md:text-lg text-slate-300 ">
                You can still contact us at
              </p>
              <p className="text-center text-sm sm:text-base md:text-lg text-blue-400 ">
                admin@alerttheglobe.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDisable
