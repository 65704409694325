import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import ContentListComponent from '../contentListing/contentListComponent'
import { CONTENT_FILTERING, GET_CATEGORY } from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { isEmpty, PAGE_SIZE } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import { decode } from 'js-base64'

let totalRecords = 0
let currentPage = 1
let apiHit = false
let orderBy = 'modified_date'
let reverse = false
const CategoryListingView = () => {
  const categoryId = useParams().categoryId
  const [searchParams] = useSearchParams()
  const categoryName = decode(searchParams.get('category'))
  const { setLoader } = useContext(AppContext)
  const { t } = useTranslation()
  const [dataList, setDataList] = useState([])
  const [categories, setCategories] = useState([])

  const [{ data: categoryRes, loading: categoryLoading }] = useAxios({
    url: GET_CATEGORY,
    method: 'get',
  })

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  const hitAPI = (page, orderBy, reverse) => {
    const filters = searchParams.get('filters')
    if (!isEmpty(filters)) {
      const payload = JSON.parse(atob(filters))
      payload['page_size'] = PAGE_SIZE
      payload['page'] = page
      if (categoryId === '623c5f940e68983f916af609') {
        payload['order_by'] = orderBy ? orderBy : 'modified_date'
        payload['reverse'] = reverse ? reverse : false
      }

      apiHit = true
      contentFilterCall({
        data: payload,
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(categories)) {
      let categoryObj = categories.find((item) => item._id === categoryId)
      if (categoryObj) {
        orderBy = categoryObj?.order_by ? categoryObj?.order_by : 'modified_date'
        reverse = categoryObj?.reverse ? categoryObj?.reverse : false
        hitAPI(1, categoryObj?.order_by, categoryObj?.reverse)
        currentPage = 1
        setDataList([])
        totalRecords = 0
      }
    }
  }, [categoryId, categories])

  useEffect(() => {
    if (categoryRes && categoryRes.success) {
      setCategories(categoryRes.data)
    }
  }, [categoryRes])

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList((dataList) => [...dataList, ...contentList.data])
      totalRecords = contentList.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    setLoader(contentListLoading || categoryLoading)
  }, [contentListLoading, categoryLoading])

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [categoryId])

  const infiniteScroll = () => {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.8 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1
        hitAPI(currentPage, orderBy, reverse)
      }
    }
  }

  return (
    <ContentListComponent
      title={categoryName}
      data={dataList}
      noContentText={contentListLoading || categoryLoading ? '' : t('no_content_category')}
    />
  )
}

export default CategoryListingView
